
































































import { Vue, Component } from 'vue-property-decorator';
import Layout from '@/layouts/LayoutDefault.vue';
import { ElUpload } from 'element-ui/types/upload';
import Routing from 'fos-routing';
import { namespace } from 'vuex-class';
import { VForm } from '@/types/v-form';

const firmModule = namespace('firm');

@Component({
  components: {
    Layout,
  },
})
export default class SharedFolderView extends Vue {
  placeholderFile: string = '';

  sharedFile: any = {
    label: '',
    file: null,
  };

  get rules(): any {
    const rules: any = {
      label: [
        {
          required: true,
          message: 'Merci de renseigner un nom',
          trigger: 'blur',
        },
      ],
      file: [
        {
          required: true,
          message: 'Merci de sélectionner un fichier',
          trigger: 'blur',
        },
      ],
    };

    return rules;
  }

  public async created() {
    await this.preload();
  }

  @firmModule.Action('loadSharedFiles')
  loadSharedFiles!: () => any;

  @firmModule.Getter('getSharedFiles')
  getSharedFiles!: () => any;

  @firmModule.Action('postSharedFile')
  postSharedFile!: (form: any) => Promise<any>;

  @firmModule.Action('deleteSharedFile')
  deleteSharedFile: any;

  loading: boolean = false;

  submitUpload() {
    const form = this.$refs.form as VForm;
    const upload = this.$refs.upload as ElUpload;

    let validForm = false;
    let errorFields = {};
    form.validate((valid, errors) => {
      validForm = valid;
      errorFields = errors;
    });

    if (validForm) {
      return this.postSharedFile(this.sharedFile).then(() => {
        this.$message({
          type: 'success',
          message: 'Fichier ajouté avec succès',
        });
        this.loadSharedFiles();
        this.sharedFile = {
          label: '',
          file: null,
        };
        upload.clearFiles();
      }).catch((error: any) => {
        this.$message({
          type: 'error',
          message: 'Erreur lors de la création du fichier',
        });
        return false;
      });
    }
    this.$message({
      message: 'Merci de renseigner les champs obligatoires',
      type: 'error',
      duration: 5000,
    });
    return false;
  }

  handleUploadSuccess(response: any) {
    this.sharedFile.file = response;
  }

  uploadRoute() {
    return Routing.generate('api_upload_shared_file');
  }

  handleDownload(sharedFile: any) {
    window.open(sharedFile.file);
  }

  handleDelete(sharedFile: any) {
    this.$confirm('Souhaitez-vous supprimer définitivement le fichier ?', 'Attention', {
      confirmButtonText: 'Supprimer le fichier',
      cancelButtonText: 'Annuler',
      type: 'warning',
    }).then(async () => {
      await this.deleteSharedFile(sharedFile.id);
      this.$message({
        type: 'success',
        message: 'Fichier supprimé',
      });
      this.loadSharedFiles();
    }).catch((error) => {
      if (error === 'cancel') {
        this.$message({
          type: 'info',
          message: 'La demande a été annulée',
        });
      } else {
        this.$message({
          type: 'warning',
          message: 'Une erreur à eu lieu lors de la suppression.',
        });
      }
    });
  }

  async preload() {
    await this.loadSharedFiles();
  }
}
